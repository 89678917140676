
import { defineComponent, useContext, useRoute, ref, Ref, useMeta } from '@nuxtjs/composition-api'
import LandingModel from '~/model/Landings/Landing'
import LandingBanner from '~/components/Page/Landings/LandingBanner.vue'
import LandingRectangularBanner from '~/components/Page/Landings/LandingRectangularBanner.vue'
import LandingSlider from '~/components/Page/Landings/LandingSlider.vue'
import LandingSectionTextImage from '~/components/Page/Landings/LandingSectionTextImage.vue'
import LandingVideo from '~/components/Page/Landings/LandingVideo.vue'
import LandingImage from '~/components/Page/Landings/LandingImage.vue'
import LandingTextBlock from '~/components/Page/Landings/LandingTextBlock.vue'
import LandingBenefits from '~/components/Page/Landings/LandingBenefits.vue'
import LandingFeedbackForm from '~/components/Page/Landings/LandingFeedbackForm.vue'
import Seo from '~/utils/seo'

export default defineComponent({
  name: 'LandingPage',
  components: {
    LandingBanner,
    LandingRectangularBanner,
    LandingSlider,
    LandingSectionTextImage,
    LandingVideo,
    LandingImage,
    LandingTextBlock,
    LandingBenefits,
    LandingFeedbackForm
  },
  setup() {
    const context = useContext()
    const route = useRoute()
    const routeSlug = route.value.params.slug
    const page: Ref<LandingModel> = ref(new LandingModel(null)) as Ref<LandingModel>
    const headers = Seo.getHeaderMeta(context.$tstore.pageMetadata.getSeoMetadata)

    const getLandingPage = async () => {
      page.value = await context.$services.landingService.getItemModel(routeSlug)
      context.$tstore.layout.setHideFooter(page.value.settings.is_hide_footer)
      context.$tstore.layout.setHideHeader(page.value.settings.is_hide_header)
    }

    useMeta({
      title: headers.title + '- Я в Агро'
    })

    getLandingPage()
    return {
      page,
      headers
    }
  },
  head: {}
})
