
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import type { LandingVideoBlockModel } from '~/model/Landings'

export default defineComponent({
  name: 'LandingBanner',
  props: {
    blockContent: {
      type: Object as PropType<LandingVideoBlockModel>,
      default: () => null
    }
  }
})
