
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'
import RoundButton from '~/components/UI/RoundButton.vue'
import type { LandingImagesBlockModel } from '~/model/Landings'

export default defineComponent({
  name: 'LandingSlider',
  components: { RoundButton, AppLazyImage },
  props: {
    blockContent: {
      type: Object as PropType<LandingImagesBlockModel>,
      default: () => {}
    },
    headTitle: {
      type: String,
      default: ''
    }
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 3,
      spaceBetween: 32,
      navigation: {
        nextEl: '#button-next',
        prevEl: '#button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        '0': {
          slidesPerView: 'auto',
          spaceBetween: 16
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 24
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 32
        }
      }
    }

    return {
      swiperOptions
    }
  }
})
