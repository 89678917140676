
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import type { LandingTextBlockModel } from '~/model/Landings'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'

export default defineComponent({
  name: 'LandingTextBlock',
  components: { AppLazyImage },
  props: {
    blockContent: {
      type: Object as PropType<LandingTextBlockModel>,
      default: () => null
    }
  }
})
