
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'
import type { LandingImageBlockModel } from '~/model/Landings'

export default defineComponent({
  name: 'LandingBanner',
  components: { AppLazyImage },
  props: {
    blockContent: {
      type: Object as PropType<LandingImageBlockModel>,
      default: () => null
    }
  }
})
