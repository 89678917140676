
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'
import type { LandingTextImageBlockModel } from '~/model/Landings'

export default defineComponent({
  name: 'LandingSectionTextImage',
  components: { AppLazyImage },
  props: {
    blockContent: {
      type: Object as PropType<LandingTextImageBlockModel>,
      default: () => null
    }
  }
})
