
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiButtonLink } from '@svoi-ui/components/button'
import AppLazyImage from '~/components/UI/AppLazyImage.vue'
import { LandingRectangularBannerBlockModel } from '~/model/Landings'

export default defineComponent({
  name: 'LandingRectangularBanner',
  components: { SvoiButtonLink, AppLazyImage },
  props: {
    blockContent: {
      type: Object as PropType<LandingRectangularBannerBlockModel>,
      default: () => {}
    }
  },
  setup(props) {
    const buttonClass = computed(() =>
      props.blockContent.button.align ? `-align-${props.blockContent.button.align}` : ''
    )

    const componentTypeButton = computed(() =>
      props.blockContent.button.link.includes('https') || props.blockContent.button.link.includes('http') ? 'a' : ''
    )

    return {
      buttonClass,
      componentTypeButton
    }
  }
})
